<template>
  <div>
    <div class="wrapper d-flex aling-items-stretch">
      <nav
        class="sidebar bg-dark"
        :class="{hide:!showSidebar}"
      >
        <div class="brand-logo">
          <router-link
            class="navbar-brand"
            to="/"
          >
            eduBudget
          </router-link>
        </div>
        <ul class="list-group list-group-flush">
          <router-link
            :to="{name:'academies'}"
            tag="li"
            class="list-group-item"
            active-class="active"
          >
            <span class="icon">
              <i class="fas fa-graduation-cap" />
            </span>
            Academies
          </router-link>

          <router-link
            :to="{name:'course_types'}"
            tag="li"
            class="list-group-item"
            active-class="active"
          >
            <span class="icon">
              <i class="fas fa-certificate" />
            </span>
            Course Types
          </router-link>

          <router-link
            :to="{name:'suppliers'}"
            tag="li"
            class="list-group-item"
            active-class="active"
          >
            <span class="icon">
              <i class="fas fa-box-open" />
            </span>
            Suppliers
          </router-link>

          <router-link
            :to="{name:'agency'}"
            tag="li"
            class="list-group-item"
            active-class="active"
          >
            <span class="icon">
              <i class="fas fa-address-book" />
            </span>
            Agency
          </router-link>

          <router-link
            :to="{name:'countries'}"
            tag="li"
            class="list-group-item"
            active-class="active"
          >
            <span class="icon">
              <i class="fas fa-globe" />
            </span>
            Countries
          </router-link>

          <router-link
            :to="{name:'cities'}"
            tag="li"
            class="list-group-item"
            active-class="active"
          >
            <span class="icon">
              <i class="fas fa-city" />
            </span>
            Cities
          </router-link>

          <router-link
            :to="{name:'user_options'}"
            tag="li"
            class="list-group-item"
            active-class="active"
          >
            <span class="icon">
              <i class="fas fa-cog" />
            </span>
            User Options
          </router-link>
        </ul>

        <div class="sidebar-footer">
          <a
            v-if="isLoggedIn"
            class="nav-item nav-link text-white"
            href="javascript:void(0)"
            @click.prevent="logOut"
          >
            <i class="fas fa-power-off" />
            Logout
          </a>
          <small>
            Powered by <a
              href="http://farenasoft.com"
              target="blank"
            >FarenaSoft &reg;</a>
          </small>
        </div>
      </nav>

      <div
        class="content"
        :class="{showingSidebar: showSidebar}"
      >
        <div class="top-nav">
          <button
            class="btn sideBarToggler"
            @click="showSidebar = !showSidebar"
          >
            <i class="fas fa-bars" />
          </button>
        </div>
        <router-view
          :key="$route.fullPath"
          class="mh-100"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import mobileChecker from '@/mixins/mobileChecker';

export default {
  mixins: [mobileChecker],
  data: () => ({
    showSidebar: true,
  }),
  computed: {
    ...mapGetters(['isLoggedIn']),
  },
  mounted() {
    if (this.isMobile) {
      this.showSidebar = false;
    }
  },
  destroyed() {
  },
  methods: {
    ...mapActions(['logout']),
    logOut() {
      this.logout().then(() => this.$router.push({ name: 'Login' }));
    },
    toggleSidebar() {
      this.showSidebar = !this.showSidebar;
    },
  },
};
</script>
<style scoped>
.wrapper {
  margin-bottom: 0;
}
</style>
<style lang="scss" scoped>
nav.sidebar {
  padding: 0;
  width: 250px !important;
  display: flex;
  flex-direction: column;
  transition: ease 1s;

  -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);

  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 2;

  &.hide {
    left: -250px;
    transition: ease 1s;
    -webkit-box-shadow: unset;
    -moz-box-shadow: unset;
    box-shadow: unset;
  }

  .brand-logo {
    color: white !important;
    text-align: center;
    padding-top: 8px;
    height: 70px;

    a {
      color: inherit !important;
    }
  }

  .sidebar-footer {
    padding: 10px 0 8px 0;
    margin-top: auto;
    text-align: center;
    color: white;
  }

  .list-group {
    .list-group-item {
      background: transparent !important;
      color: white;
      font-size: .9em;
      border-color: rgba(255,255,255,.3);
      cursor: pointer;
      padding-left: 10px;
      padding-right: 10px;

      &:hover:not(.active) {
        color:  rgba(255,255,255,.5);
      }

      &.active {
        background: rgba(255,255,255,.3) !important;
      }

      .icon {
        width: 30px;
        text-align: center;
        display: inline-block;
      }
    }
  }
}

.content {
  width: 100%;
  padding: 1em 1.5em;
  transition: 1s ease;

  &.showingSidebar {
    transition: 1s ease;
    margin-left: 250px;
  }

  .top-nav {
    height: 70px;

    .sideBarToggler {
      padding: 0;
      box-shadow: unset;
      cursor: pointer;
    }
  }

  @media(min-width: 768px) {
    padding: 1em 2.5em;
  }

}
</style>
